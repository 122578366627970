@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?c9urjl');
  src:  url('fonts/icomoon.eot?c9urjl#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?c9urjl') format('truetype'),
    url('fonts/icomoon.woff?c9urjl') format('woff'),
    url('fonts/icomoon.svg?c9urjl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-highlight:before {
  content: "\e908";
}
.icon-callout:before {
  content: "\e900";
}
.icon-text:before {
  content: "\e901";
}
.icon-polycloud:before {
  content: "\e902";
}
.icon-submenu:before {
  content: "\e903";
}
.icon-arrow:before {
  content: "\e905";
}
.icon-cloud:before {
  content: "\e906";
}
.icon-shape:before {
  content: "\e907";
}
.icon-freehand:before {
  content: "\e909";
}
.icon-undo:before {
  content: "\e90a";
}
.icon-redo:before {
  content: "\e90b";
}
.icon-hexagon:before {
  content: "\e90c";
}
.icon-quill:before {
  content: "\e90d";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-dimensions:before {
  content: "\e939";
}
