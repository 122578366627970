#MarkupsGuiControlbar {
    position: absolute;
    bottom: 10px !important;
    right: 0 !important;
    left: 0 !important;
    top: auto !important;
    margin: 0 auto;
}
#MarkupsGuiControlbar .adsk-input input {
    background: white;
    border-radius: 6px;
    border: 2px solid #2f2f2f;
}

body .adsk-viewing-viewer .adsk-toolbar .adsk-button .adsk-button-icon {
    font-size: 16px !important;
}
