.lockInfo {
    z-index: 1000;
    padding: 10px;
    border-radius: 4px;
    white-space: nowrap;
    margin-bottom: 20px;
}

.lockInfo--running {
    background: #B9DBDB;
}

.lockInfo--failed {
    background: #FFBABA;
    padding-left: 20px;
}
