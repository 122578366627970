.root {
    max-width: 800px;
    @apply mx-auto w-full flex flex-col;
    height: 100%;
    gap: 1rem;
    padding: 1rem;
    box-sizing: border-box;
}
.partTitle {
}
.partTabs {
    @apply flex flex-row;
    gap: .6rem;
}
.partMain {
    flex: 1;
    overflow: auto;
}
