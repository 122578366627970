@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    *,
    ::before,
    ::after {
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
    }

    html {
        font-size: 90%;
    }

    .ext-button--rotate {
        background-image: url("data:image/svg+xml,%3Csvg class='svg-icon' style='width: 1em;height: 1em;vertical-align: middle;fill: white;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 798.293333V640h85.333333v298.666667H85.333333v-85.333334h104.469334A468.650667 468.650667 0 0 1 42.666667 512C42.666667 252.8 252.8 42.666667 512 42.666667v85.333333C299.925333 128 128 299.925333 128 512c0 111.573333 47.957333 214.698667 128 286.293333z m512-572.586666V384h-85.333333V85.333333h298.666666v85.333334h-147.136A468.650667 468.650667 0 0 1 981.333333 512c0 259.2-210.133333 469.333333-469.333333 469.333333v-85.333333c212.074667 0 384-171.925333 384-384 0-111.573333-47.957333-214.698667-128-286.293333z m-256 105.28L693.013333 512 512 693.013333 330.986667 512 512 330.986667z m0 241.344L572.330667 512 512 451.669333 451.669333 512 512 572.330667z' /%3E%3C/svg%3E");
    }

    .ext-button--move {
        background-image: url("data:image/svg+xml,%3Csvg class='svg-icon' style='width: 1em; height: 1em;vertical-align: middle;fill: white;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M768 320v128H576V256h128L512 64 320 256h128v192H256V320L64 512l64 64 128 128V576h192v192H320l192 192 64-64 128-128H576V576h192v128l192-192-192-192z' /%3E%3C/svg%3E");
    }

    .ext-button--copy {
        background-image: url("data:image/svg+xml,%3Csvg class='svg-icon' style='width: 1em; height: 1em;vertical-align: middle;fill: white;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M469.333333 426.666667H384v128H256v85.333333h128v128h85.333333v-128h128v-85.333333h-128z' fill='' /%3E%3Cpath d='M128 938.666667h597.333333a42.666667 42.666667 0 0 0 42.666667-42.666667V298.666667a42.666667 42.666667 0 0 0-42.666667-42.666667H128a42.666667 42.666667 0 0 0-42.666667 42.666667v597.333333a42.666667 42.666667 0 0 0 42.666667 42.666667zM170.666667 341.333333h512v512H170.666667V341.333333z' fill='' /%3E%3Cpath d='M896 85.333333H341.333333v85.333334h512v512h85.333334V128a42.666667 42.666667 0 0 0-42.666667-42.666667z' fill='' /%3E%3C/svg%3E");
    }

    .ext-button--create {
        background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg transform='matrix(1,0,0,1,-2,2)'%3E%3Cpath d='M20,2L8,2C6.9,2 6,2.9 6,4L6,16C6,17.1 6.9,18 8,18L20,18C21.1,18 22,17.1 22,16L22,4C22,2.9 21.1,2 20,2ZM20,16L8,16L8,4L20,4L20,16ZM13,15L15,15L15,11L19,11L19,9L15,9L15,5L13,5L13,9L9,9L9,11L13,11L13,15Z' style='fill:white;fill-rule:nonzero;'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    .ext-button--delete {
        background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M6,19C6,20.1 6.9,21 8,21L16,21C17.1,21 18,20.1 18,19L18,7L6,7L6,19ZM19,4L15.5,4L14.5,3L9.5,3L8.5,4L5,4L5,6L19,6L19,4Z' style='fill:white;fill-rule:nonzero;'/%3E%3C/svg%3E");
     }

    .ext-button--qrscan {
        background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M9.5,6.5L9.5,9.5L6.5,9.5L6.5,6.5L9.5,6.5M11,5L5,5L5,11L11,11L11,5ZM9.5,14.5L9.5,17.5L6.5,17.5L6.5,14.5L9.5,14.5M11,13L5,13L5,19L11,19L11,13ZM17.5,6.5L17.5,9.5L14.5,9.5L14.5,6.5L17.5,6.5M19,5L13,5L13,11L19,11L19,5ZM13,13L14.5,13L14.5,14.5L13,14.5L13,13ZM14.5,14.5L16,14.5L16,16L14.5,16L14.5,14.5ZM16,13L17.5,13L17.5,14.5L16,14.5L16,13ZM13,16L14.5,16L14.5,17.5L13,17.5L13,16ZM14.5,17.5L16,17.5L16,19L14.5,19L14.5,17.5ZM16,16L17.5,16L17.5,17.5L16,17.5L16,16ZM17.5,14.5L19,14.5L19,16L17.5,16L17.5,14.5ZM17.5,17.5L19,17.5L19,19L17.5,19L17.5,17.5ZM22,7L20,7L20,4L17,4L17,2L22,2L22,7ZM22,22L22,17L20,17L20,20L17,20L17,22L22,22ZM2,22L7,22L7,20L4,20L4,17L2,17L2,22ZM2,2L2,7L4,7L4,4L7,4L7,2L2,2Z' style='fill:white;fill-rule:nonzero;'/%3E%3C/svg%3E");
    }

    .ext-button--ok {
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23008800'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z'/%3E%3C/svg%3E");
    }

    .ext-button--cancel {
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23aa0000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none' opacity='.87'/%3E%3Cpath d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z'/%3E%3C/svg%3E");
    }

    .ext-button--activated {
        background-color: rgba(255, 255, 255, 0.5);
    }

    #AdjustGeometryTool #createSubMenu svg {
        stroke: white;
        width: 100%;
        height: 100%;
    }

    /* disable keyboard offset */
    ion-content {
        --keyboard-offset: 0 !important;
    }

    /* FSM export tree */
    .rst__rowWrapper {
        padding: 3px 3px 3px 0 !important;
    }
    .rst__rowContents, .rst__moveHandle, .rst__loadingHandle {
        box-shadow: none !important;
    }
    .rst__rowLabel {
        padding: 0 !important;
    }

}
